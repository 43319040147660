@import "react-datepicker/dist/react-datepicker.css";
body {
  .react-datepicker-wrapper {
    display: block;
  }
  .react-datepicker__tab-loop {
    & + .form_placeholder {
      font-size: 1.4rem;
      top: 12px;
      @include desktopStyle {
        top: get-vw(12px);
      }
      @media only screen and (max-width: $bp-tablet) {
        font-size: 1.2rem;
      }
    }
  }

  .react-datepicker-popper {
    z-index: 10;
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 130px;
    @include desktopStyle {
      right: get-vw(130px);
    }
    @media only screen and (max-width: $bp-phone) {
      right: 90px;
    }
  }
  .react-datepicker__time-container {
    width: 130px;
    @include desktopStyle {
      width: get-vw(130px);
    }
    @media only screen and (max-width: $bp-phone) {
      width: 90px;
    }
    .react-datepicker__time {
      .react-datepicker__time-box {
        width: 100%;
        .react-datepicker__time-list {
          &::-webkit-scrollbar {
            width: 6px;
          }
          &::-webkit-scrollbar-track {
            background: var(--blue-light);
          }
          &::-webkit-scrollbar-thumb {
            background: rgba(var(--blue-rgba), 0.8);
            border-radius: 4px;
          }
        }
      }
    }
  }

  .react-datepicker__day--disabled {
    opacity: 0.4;
  }

  .react-datepicker {
    width: 100%;
    border: 1px solid var(--gray-second);
    vertical-align: top;
    border-radius: 12px;
    @include desktopStyle {
      border-radius: get-vw(12px);
    }
  }
  .react-datepicker__header {
    background: transparent;
    border: none;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-family: $font-main-B;
    font-size: 1.8rem;
    line-height: 2;
    letter-spacing: -0.02em;
    text-align: center;
    color: var(--gray-dark);
    padding: 5px 0;
    @include desktopStyle {
      padding: get-vw(5px);
    }
    @media only screen and (max-width: $bp-phone) {
      font-size: 1.6rem;
    }
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name,
  .react-datepicker__time-list-item {
    font-family: $font-main-MD;
    font-size: 1.6rem;
    line-height: 1;
    letter-spacing: -0.01em;
    text-align: center;
    width: 32px;
    @include desktopStyle {
      width: get-vw(32px);
    }
    @media only screen and (max-width: $bp-phone) {
      width: 24px;
      font-size: 1.4rem;
    }
  }
  .react-datepicker__time-list-item {
    width: auto;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: var(--blue) !important;
      color: var(--white);
    }
    @media only screen and (max-width: $bp-phone) {
      font-size: 1.2rem;
    }
  }
  .react-datepicker__day-names {
    margin-bottom: 12px;
    margin-top: 12px;
    @include desktopStyle {
      margin-bottom: get-vw(12px);
      margin-top: get-vw(12px);
    }
  }
  .react-datepicker__day-name {
    margin: 0 2px;
    @include desktopStyle {
      margin: 0 get-vw(2px);
    }
  }
  .react-datepicker__month {
    margin: 0;
  }
  .react-datepicker__day,
  .react-datepicker__month-text,
  .react-datepicker__quarter-text,
  .react-datepicker__year-text {
    color: var(--gray-dark);
    border-radius: 8px;
    border: 1px solid transparent;
    margin: 2px;
    padding: 0;
    height: 32px;
    line-height: 32px;
    transition: all 0.3s;
    @include desktopStyle {
      border-radius: get-vw(8px);
      margin: get-vw(2px);
      height: get-vw(32px);
      line-height: get-vw(32px);
    }
    @media only screen and (max-width: $bp-phone) {
      height: 24px;
      line-height: 24px;
    }
    &:hover {
      border-color: var(--blue);
      background: transparent;
    }
  }
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    color: var(--gray-dark);
    background: rgba(var(--blue-rgba), 0.4);
    border-color: rgba(var(--blue-rgba), 0.4);
  }
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__year-text--in-selecting-range {
    &:not(
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--in-range
      ) {
      color: var(--gray-dark);
      background: rgba(var(--blue-rgba), 0.4);
      border-color: rgba(var(--blue-rgba), 0.4);
    }
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selecting-range-start,
  .react-datepicker__day--selecting-range-end,
  .react-datepicker__day--range-start,
  .react-datepicker__day--range-end,
  .react-datepicker__time-list-item--selected {
    color: var(--white) !important;
    background: var(--blue) !important;
    border-color: var(--blue) !important;
  }
  .react-datepicker__day--outside-month {
    opacity: 0;
    vertical-align: bottom;
  }
  .react-datepicker__day--today {
    font-weight: 700;
  }
  .react-datepicker__navigation {
    top: 18px;
    @include desktopStyle {
      top: get-vw(18px);
    }
    @media only screen and (max-width: $bp-phone) {
      top: 15px;
    }
  }
  .react-datepicker__header--time {
    display: none;
  }
}
