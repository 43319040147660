h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: $font-main-SB;
  font-weight: 600;
  line-height: 1.2;
  margin: 0;
}

h1,
.h1 {
  font-size: 6.4rem;
  @media only screen and (max-width: $bp-tablet) {
    font-size: 4.8rem;
  }
  @media only screen and (max-width: $bp-phone) {
    font-size: 3.2rem;
  }
}

h2,
.h2 {
  font-size: 5.6rem;
  @media only screen and (max-width: $bp-desktop-sm) {
    font-size: 5rem;
  }
  @media only screen and (max-width: $bp-tablet) {
    font-size: 3.6rem;
  }
  @media only screen and (max-width: $bp-phone) {
    font-size: 2.8rem;
  }
}

h3,
.h3 {
  font-size: 4.4rem;
  @media only screen and (max-width: $bp-desktop-sm) {
    font-size: 4rem;
  }
  @media only screen and (max-width: $bp-tablet) {
    font-size: 3.2rem;
  }
  @media only screen and (max-width: $bp-phone) {
    font-size: 2.6rem;
  }
}

h4,
.h4 {
  font-size: 3.2rem;
  @media only screen and (max-width: $bp-tablet) {
    font-size: 2.8rem;
  }
  @media only screen and (max-width: $bp-phone) {
    font-size: 2.4rem;
  }
}

h5,
.h5 {
  font-size: 2.4rem;
  @media only screen and (max-width: $bp-tablet) {
    font-size: 2.2rem;
  }
  @media only screen and (max-width: $bp-phone) {
    font-size: 2rem;
  }
}

h6,
.h6 {
  font-size: 1.8rem;
  @media only screen and (max-width: $bp-phone) {
    font-size: 1.6rem;
  }
}

.text-2 {
  font-family: $font-main-MD;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.6;
  @media only screen and (max-width: $bp-tablet) {
    font-size: 1.7rem;
  }
  @media only screen and (max-width: $bp-phone) {
    font-size: 1.5rem;
  }
}

.text-3 {
  font-family: $font-main-MD;
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 1.5;
  @media only screen and (max-width: $bp-tablet) {
    font-size: 2rem;
  }
  @media only screen and (max-width: $bp-phone) {
    font-size: 1.8rem;
    line-height: 1.6;
  }
}

.text-4 {
  font-size: 2rem;
  line-height: 1.6;
  @media only screen and (max-width: $bp-phone) {
    font-size: 1.8rem;
  }
}

p,
figure.media {
  margin-bottom: 16px;
  @include desktopStyle {
    margin-bottom: get-vw(16px);
  }
  @media only screen and (max-width: $bp-phone) {
    margin-bottom: 12px;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

strong,
b {
  font-family: $font-main-B;
  i, em {
    font-family: $font-main-BI;
  }
}

i, em {
  font-family: $font-main-MDI;
}

.rich-text {
  a {
    color: var(--blue);
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    margin-top: 40px;
    color: var(--gray-dark);
    @include desktopStyle {
      margin-top: get-vw(40px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-top: 32px;
    }
    @media only screen and (max-width: $bp-phone) {
      margin-top: 24px;
    }
    &:first-child {
      margin-top: 0;
    }
  }
  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4 {
    margin-bottom: 32px;
    @include desktopStyle {
      margin-bottom: get-vw(32px);
    }
    @media only screen and (max-width: $bp-phone) {
      margin-bottom: 24px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  h5, .h5 {
    margin-bottom: 24px;
    @include desktopStyle {
      margin-bottom: get-vw(24px);
    }
    @media only screen and (max-width: $bp-phone) {
      margin-bottom: 16px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  h6, .h6 {
    margin-bottom: 16px;
    @include desktopStyle {
      margin-bottom: get-vw(16px);
    }
    &:last-child {
      margin-bottom: 0;
    }
    & + p {
      font-size: 1.6rem;
      @media only screen and (max-width: $bp-phone) {
        font-size: 1.5rem;
      }
    }
  }

  ul,
  ol {
    margin: 24px 0;
    padding-inline-start: 32px;
    @include desktopStyle {
      margin-top: get-vw(24px);
      margin-bottom: get-vw(24px);
      padding-inline-start: get-vw(32px);
    }

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }

    li {
      margin-bottom: 12px;
      @media only screen and (max-width: $bp-phone) {
        margin-bottom: 8px;
      }
      &:last-child {
        margin-bottom: 0;
      }

      & > ul,
      & > ol,
      & > ul:first-child,
      & > ol:first-child {
        margin-top: 12px;
        @media only screen and (max-width: $bp-phone) {
          margin-top: 8px;
        }
      }
    }

    &.no-margins {
      li {
        margin-bottom: 0 !important;
      }
    }
  }

  ul {
    list-style-type: disc;    
    li {
      &.icon-check,
      &.icon-minus {
        position: relative;
        list-style: none;
        &::after {
          content: '';
          position: absolute;
          display: block;
          width: 20px;
          height: 20px;
          top: 5px;
          left: -32px;
          background-position: center center;
          background-size: contain;
          background-repeat: no-repeat;
          @include desktopStyle {
            width: get-vw(20px);
            height: get-vw(20px);
            top: get-vw(5px);
            left: get-vw(-32px);
          }
          @media only screen and (max-width: $bp-phone) {
            top: 2px;
          }
        }
      }
      &.icon-check {
        &::after {
          background-image: url('~~/app/assets/images/icons/check-circle.svg');
        }
      }
      &.icon-minus {
        &::after {
          background-image: url('~~/app/assets/images/icons/minus-circle.svg');
        }
      }
    }
  }

  // Nested ordered list with auto numbering
  ol {
    counter-reset: item;
    list-style-type: decimal;
    & > li {
      counter-increment: item;
      ol {
        list-style: none !important;
        & > li:before {
          content: counters(item, '.') '. ';
        }
      }
    }

    li {
      &.icon-num {
        padding-left: 4px;
        position: relative;
        list-style: none;
        @include desktopStyle {
          padding-left: get-vw(4px);
        }

        &::after {
          content: '';
          position: absolute;
          display: block;
          width: 24px;
          height: 24px;
          top: 50%;
          left: -32px;
          margin-top: -12px;
          background-position: center center;
          background-size: contain;
          background-repeat: no-repeat;
          @include desktopStyle {
            width: get-vw(24px);
            height: get-vw(24px);
            left: get-vw(-32px);
            margin-top: get-vw(-12px);
          }
        }

        @for $i from 1 through 6 {
          &:nth-child(#{$i}) {
            &::after {
              background-image: url('~~/app/assets/images/icons/circle-#{$i}.svg');
            }
          }
        }
      }
    }
  }
}

.ws-pw {
  white-space: pre-wrap;
}

.ws-pl {
  white-space: pre-line;
}

.fw-400 {
  font-family: $font-main;
  font-weight: 400;
}

.fw-500 {
  font-family: $font-main-MD;
  font-weight: 500;
}

.fw-500-i {
  font-family: $font-main-MDI;
  font-weight: 500;
  font-style: italic;
}

.fw-600 {
  font-family: $font-main-SB;
  font-weight: 600;
}

.fw-700 {
  font-family: $font-main-B;
  font-weight: 700;
}

.fw-700-i {
  font-family: $font-main-BI;
  font-weight: 700;
  font-style: italic;
}
