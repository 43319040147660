@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
@import "./normalize.scss";
@import "./fonts.scss";
@import "./typography.scss";
@import "./forms.scss";
@import "./utility.scss";
@import "./theme.scss";
@import "./datepicker.scss";

* {
  box-sizing: border-box;
  &::after,
  &::before {
    box-sizing: border-box;
  }
}

img {
  max-width: 100%;
}

html,
body {
  min-height: 100%;
  font-weight: 400;
  line-height: 1.6;
  width: 100%;
  font-family: $font-main-MD;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-synthesis: none;
  color: var(--gray-dark);
  position: relative;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: var(--blue-light);
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(var(--blue-rgba), 0.8);
    border-radius: 4px;
  }
}

html {
  font-size: 10px;
  scroll-behavior: smooth;
  @include desktopStyle {
    font-size: get-vw(10px);
  }
}

body {
  font-size: 2rem;
  @media only screen and (max-width: $bp-tablet) {
    font-size: 1.7rem;
  }
  @media only screen and (max-width: $bp-phone) {
    font-size: 1.5rem;
  }

  &.b-white-header {
    .header {
      background: transparent;
    }
  }

  &.b-show-cookie-modal {
    @media only screen and (min-width: $bp-phone + 1) {
      #areon_chat_web_widget_loaded,
      .acbsww.acbsww_container {
        bottom: 145px;
        transition: bottom 0.3s;
        @include desktopStyle {
          bottom: get-vw(145px);
        }
      }
    }
    .bingc-phone-button {
      @media only screen and (max-width: $bp-tablet - 1) and (min-width: $bp-phone + 1) {
        bottom: 135px !important;
      }
      @media only screen and (max-width: $bp-phone) {
        left: auto !important;
        right: 80px;
      }
    }
    .button-scroll-top {
      bottom: 195px;
      @media only screen and (min-width: $bp-phone + 1) {
        bottom: 215px;
        transition: bottom 0.3s;
        @include desktopStyle {
          bottom: get-vw(235px);
        }
      }
    }
  }

  .bingc-phone-button {
    bottom: 10px !important;
    @media only screen and (max-width: $bp-phone) {
      transform-origin: bottom center;
      transform: scale(.7) !important;
    }
  }
}

a {
  word-break: break-word;
  transition: color 0.3s;
}

blockquote {
  border-left: 5px solid #ccc;
  padding: 16px 24px;
  margin-bottom: 16px;
  @include desktopStyle {
    margin-bottom: get-vw(16px);
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.main {
  & > div:first-child {
    &:not(.thanks-page-hero) {
      @media only screen and (max-width: $bp-tablet) {
        margin-top: 0;
        padding-top: 104px;
      }
    }
  }
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid var(--white);
  border-bottom-color: transparent;
  border-radius: 100%;
  display: inline-block;
  animation: rotation 1s linear infinite;
  @include desktopStyle {
    width: get-vw(48px);
    height: get-vw(48px);
  }
}

.animation-rotation {
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.wr {
  max-width: 1632px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  @include desktopStyle {
    max-width: get-vw(1632px);
    padding-left: get-vw(16px);
    padding-right: get-vw(16px);
  }
}

.link-arrow {
  font-family: $font-main-SB;
  font-size: 1.8rem;
  line-height: 1.3;
  color: var(--gray-dark);
  gap: 16px;
  grid-gap: 16px;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  &:hover {
    color: var(--blue);
    svg {
      transform: translateX(-5px);
    }
  }
  @include desktopStyle {
    gap: get-vw(16px);
    grid-gap: get-vw(16px);
  }
  @media only screen and (max-width: $bp-phone) {
    font-size: 1.6rem;
  }
  svg {
    flex-shrink: 0;
    display: block;
    transition: transform 0.3s;
  }
}

.logo {
  display: inline-block;
  vertical-align: middle;
  max-width: 124px;
  @include desktopStyle {
    max-width: get-vw(124px);
  }
  @media only screen and (max-width: $bp-phone-sm) {
    max-width: 83px;
  }
  img {
    display: block;
  }
}

.color-blue,
.text-blue {
  color: var(--blue);
}

.icon-important {
  position: relative;
  padding-left: 36px;
  @include desktopStyle {
    padding-left: get-vw(36px);
  }
  &::after {
    content: "";
    position: absolute;
    display: block;
    background: url("~~/app/assets/images/icons/important.svg") center center no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
    left: 0;
    top: 50%;
    margin-top: -12px;
    @include desktopStyle {
      width: get-vw(24px);
      height: get-vw(24px);
      margin-top: get-vw(-12px);
    }
  }
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 0.3s;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 0.3s;
}
.fade-exit-done {
  display: none !important;
}

.slideInShort-enter {
  animation-name: fadeInRightShort;
  animation-duration: 0.3s;
}
.slideInShort-exit {
  animation-name: fadeOutLeftShort;
  animation-duration: 0.3s;
}
.slideOutShort-enter {
  animation-name: fadeInLeftShort;
  animation-duration: 0.3s;
}
.slideOutShort-exit {
  animation-name: fadeOutRightShort;
  animation-duration: 0.3s;
}
@keyframes fadeInRightShort {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
  }
}
@keyframes fadeInLeftShort {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
  }
}
@keyframes fadeOutRightShort {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0;
  }
}
@keyframes fadeOutLeftShort {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
}

.slideIn-enter {
  animation-name: fadeInRight;
  animation-duration: 0.4s;
}
.slideIn-exit {
  animation-name: fadeOutLeft;
  animation-duration: 0.4s;
}
.slideOut-enter {
  animation-name: fadeInLeft;
  animation-duration: 0.4s;
}
.slideOut-exit {
  animation-name: fadeOutRight;
  animation-duration: 0.4s;
}
@keyframes fadeInRight {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
  }
}
@keyframes fadeInLeft {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
  }
}
@keyframes fadeOutRight {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 0;
  }
}
@keyframes fadeOutLeft {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0;
  }
}
