.d-f  { display: flex; }
.d-if { display: inline-flex; }

.fd-r  { flex-direction: row; } /* Default */
.fd-rr { flex-direction: row-reverse; }
.fd-c  { flex-direction: column; }
.fd-cr { flex-direction: column-reverse; }

.fw-n  { flex-wrap: nowrap; } /* Default */
.fw-w  { flex-wrap: wrap; }
.fw-wr { flex-wrap: wrap-reverse; }

.jc-fe { justify-content: flex-end; }
.jc-fs { justify-content: flex-start; } /* Default */
.jc-c  { justify-content: center; }
.jc-sa { justify-content: space-around; }
.jc-sb { justify-content: space-between; }

.ai-fs { align-items: flex-start; }
.ai-c  { align-items: center; }
.ai-fe { align-items: flex-end; }
.ai-s  { align-items: stretch; } /* Default */
.ai-b  { align-items: baseline; }

.fg-0 { flex-grow: 0; } /* Default */
.fg-1 { flex-grow: 1; }

.fs-0 { flex-shrink: 0; }
.fs-1 { flex-shrink: 1; } /* Default */

.fb-a { flex-basis: auto; } /* Default */
.fb-0 { flex-basis: 0; }

.as-a  { align-self: auto; } /* Default */
.as-fs { align-self: flex-start; }
.as-c  { align-self: center; }
.as-fe { align-self: flex-end; }
.as-s  { align-self: stretch; }
.as-b  { align-self: baseline }

.o-h { overflow: hidden; }
