:root {
  --white: #fff;
  --white-rgba: 255, 255, 255;
  --white-rgba-2: 255, 255, 255;
  --white-2: #f6f6f6;
  --black: #000;
  --black-rgba: 0, 0, 0;
  --black-rgba-2: 0, 0, 0;

  --aiwa-color-primary: #499bd5;
  --blue: #499bd5;
  --blue-rgba: 73, 155, 213;
  --blue-light: #f0f6fb;
  --blue-light-2: #5dafe9;
  --gray: #454a53;
  --gray-2: #d0d0d0;
  --gray-rgba: 69, 74, 83;
  --gray-bg: #fafbfc;
  --gray-bg-rgba: 250, 251, 252;
  --gray-bg-2: #f7f8f9;
  --gray-second: #dadada;
  --gray-second-rgba: 218, 218, 218;
  --gray-light: #898f9a;
  --gray-light-rgba: 137, 143, 154;
  --gray-light-2: #c2c3c5;
  --gray-light-3: #eff1f2;
  --gray-dark: #151413;
  --gray-dark-rgba: 21, 20, 19;
  --gray-dark-2: #101828;
  --gray-dark-2-rgba: 16, 24, 40;
  --gray-dark-3: #333333;
  --green: #4ca465;
  --green-light: #effbf3;
  --green-light-2: #60b879;
  --green-rgba: 76, 164, 101;
  --red: #eb5757;
  --red-2: #eb5858;
  --pink: #df7666;
  --pink-light: #fdf4f2;

  --bg-blue-sky: #cddbfe;
  --bg-blue: #87afeb;
  --bg-blue-2: #98bef6;
  --bg-blue-3: #6991cd;
  --bg-blue-4: #3e4d6a;
  --bg-blue-5: #b9d5ff;
  --bg-blue-6: #7e9ff5;
  --bg-blue-light: #96c8ec;
  --bg-blue-light-2: #ecf5fd;
  --bg-blue-light-3: #bfdbee;
  --bg-blue-light-4: #ddebf9;
  --bg-blue-light-5: #99ceec;
  --bg-label-blue: #9bc3ff;
  --bg-label-blue-2: #a5cdff;
  --bg-yellow: #e2e789;
  --bg-yellow-2: #ced070;
  --bg-yellow-light: #e2e789;
  --bg-yellow-light-2: #f5f5e7;
  --bg-yellow-light-3: #f5f6d5;
  --bg-yellow-dark: #a6a848;
  --bg-label-yellow: #ecee8e;
  --bg-green: #a1c587;
  --bg-green-2: #82d09f;
  --bg-green-3: #95cf4b;
  --bg-green-4: #65ca8a;
  --bg-green-light: #f3f9ee;
  --bg-green-light-2: #c7ed97;
  --bg-green-light-3: #edf5e7;
  --bg-green-light-4: #F4FAF7;
  --bg-green-light-5: #dceae5;
  --bg-label-green: #bfe3a5;
  --bg-pink: #e8b098;
  --bg-pink-light: #f2cdb8;
  --bg-pink-light-2: #f8efea;
  --bg-pink-light-3: #fcf4f1;
  --bg-pink-dark: #fd9484;
  --bg-turquoise: #ace6f2;
  --bg-turquoise-2: #8ad7e9;
  --bg-turquoise-light: #f1fbfd;
  --bg-violet: #baa2ed;
  --bg-violet-light: #dbcaff;
  --bg-violet-light-2: #f6f2ff;
  --bg-brown: #e7cd89;
  --bg-brown-2: #dcbd46;
  --bg-brown-3: #e2a480;
  --bg-brown-dark: #ab914d;
  --bg-brown-light: #eee0ad;
  --bg-brown-light-2: #f9f7f2;
  --bg-brown-light-3: #f6f0e0;
}

html {
  // size
  &[data-font-size="md"] {
    font-size: 12px;
    @include desktopStyle {
      font-size: get-vw(12px);
    }
  }
  &[data-font-size="lg"] {
    font-size: 14px;
    @include desktopStyle {
      font-size: get-vw(14px);
    }
  }
  // theme
  &[data-color-scheme="whiteBlack"],
  &[data-color-scheme="blackWhite"],
  &[data-color-scheme="blue"] {
    [style*="color"] {
      color: inherit !important;
    }
    .select.select-with-dot {
      .select__option,
      .select__single-value {
        &:before {
          filter: grayscale(100%);
        }
      }
    }
    .map-circle {
      filter: grayscale(100%);
    }
  }
  &[data-color-scheme="whiteBlack"] {
    --aiwa-color-primary: #151413;
    --blue: #151413;
    --blue-rgba: 21, 20, 19;
    --blue-light: #fafafa;
    --blue-light-2: #151413;
    --gray: #151413;
    // --gray-rgba: 69, 74, 83;
    --gray-bg: #f2f2f2;
    --gray-bg-rgba: 242, 242, 242;
    --gray-bg-2: #828282;
    --gray-second: #828282;
    // --gray-second-rgba: 218, 218, 218;
    // --gray-light: #898F9A;
    // --gray-light-rgba: 137, 143, 154;
    // --gray-light-2: #C2C3C5;
    // --gray-light-3: #EFF1F2;
    --green: #151413;
    --green-light: #fafafa;
    --green-light-2: #151413;
    --green-rgba: 21, 20, 19;
    --red: #151413;
    --red-2: #151413;
    --pink: #828282;
    --pink-light: #fafafa;
    --bg-blue-sky: #e0e0e0;
    --bg-blue: #e0e0e0;
    --bg-blue-2: #e0e0e0;
    --bg-blue-3: #e0e0e0;
    --bg-blue-4: #e0e0e0;
    --bg-blue-5: #e0e0e0;
    --bg-blue-6: #e0e0e0;
    --bg-blue-light: #e0e0e0;
    --bg-blue-light-2: #f2f2f2;
    --bg-blue-light-3: #fafafa;
    --bg-blue-light-4: #fafafa;
    --bg-blue-light-5: #fafafa;
    --bg-label-blue: #f2f2f2;
    --bg-label-blue-2: #e0e0e0;
    --bg-yellow: #e0e0e0;
    --bg-yellow-2: #e0e0e0;
    --bg-yellow-light: #e0e0e0;
    --bg-yellow-light-2: #fafafa;
    --bg-yellow-light-3: #fafafa;
    --bg-yellow-dark: #e0e0e0; //
    --bg-label-yellow: #f2f2f2;
    --bg-green: #e0e0e0;
    --bg-green-2: #e0e0e0;
    --bg-green-3: #e0e0e0;
    --bg-green-4: #e0e0e0;
    --bg-green-light: #fafafa;
    --bg-green-light-2: #e0e0e0;
    --bg-green-light-3: #fafafa;
    --bg-green-light-4: #f2f2f2;
    --bg-green-light-5: #fafafa;
    --bg-label-green: #f2f2f2;
    --bg-pink: #e0e0e0;
    --bg-pink-light: #e0e0e0;
    --bg-pink-light-2: #fafafa;
    --bg-pink-light-3: #fafafa;
    --bg-pink-dark: #151413;
    --bg-turquoise: #e0e0e0;
    --bg-turquoise-2: #e0e0e0;
    --bg-turquoise-light: #fafafa;
    --bg-violet: #e0e0e0;
    --bg-violet-light: #e0e0e0;
    --bg-violet-light-2: #fafafa;
    --bg-brown: #e0e0e0;
    --bg-brown-2: #e0e0e0;
    --bg-brown-3: #e0e0e0;
    --bg-brown-dark: #e0e0e0;
    --bg-brown-light: #e0e0e0;
    --bg-brown-light-2: #fafafa;
    --bg-brown-light-3: #fafafa;
    *::after,
    *::before {
      filter: brightness(0%);
    }
    .slick-arrow {
      &:not(.slick-disabled):hover {
        filter: none !important;
      }
    }
    .aiwa-element-widget,
    .acbsww_container {
      filter: grayscale(100%);
    }
  }
  &[data-color-scheme="blackWhite"] {
    background-color: #333333;
    --white: #333333;
    --white-rgba-2: 51, 51, 51;
    --white-2: #2c2c2c;
    --black: #fff;
    // --black-rgba: 0, 0, 0;
    --black-rgba-2: 255, 255, 255;

    --aiwa-color-primary: #fff;
    --blue: #fff;
    --blue-rgba: 255, 255, 255;
    --blue-light: #2c2c2c;
    --blue-light-2: #fff;
    --gray: #fff;
    --gray-rgba: 255, 255, 255;
    --gray-bg: #2c2c2c;
    --gray-bg-rgba: 44, 44, 44;
    // --gray-bg-2: #f7f8f9;
    --gray-second: #e8e8e8;
    // --gray-second-rgba: 218, 218, 218;
    --gray-light: #e8e8e8;
    // --gray-light-rgba: 137, 143, 154;
    // --gray-light-2: #c2c3c5;
    --gray-light-3: #2c2c2c;
    --gray-dark: #fff;
    --gray-dark-rgba: 255, 255, 255;
    --gray-dark-2: #fff;
    --gray-dark-2-rgba: 44, 44, 44;
    --gray-dark-3: #424242;
    --green: #fff;
    --green-light: #2c2c2c;
    --green-light-2: #fff;
    --green-rgba: 242, 242, 242;
    --red: #fff;
    --red-2: #fff;
    --pink: #fff;
    --pink-light: #424242;

    --bg-blue-sky: #2c2c2c;
    --bg-blue: #2c2c2c;
    --bg-blue-2: #2c2c2c;
    --bg-blue-3: #2c2c2c;
    --bg-blue-4: #2c2c2c;
    --bg-blue-5: #2c2c2c;
    --bg-blue-6: #2c2c2c;
    --bg-blue-light: #2c2c2c;
    --bg-blue-light-2: #424242;
    --bg-blue-light-3: #424242;
    --bg-blue-light-4: #424242;
    --bg-blue-light-5: #424242;
    --bg-label-blue: #f2f2f2;
    --bg-label-blue-2: #424242;
    --bg-yellow: #2c2c2c;
    --bg-yellow-2: #2c2c2c;
    --bg-yellow-light: #424242;
    --bg-yellow-light-2: #424242;
    --bg-yellow-light-3: #424242;
    --bg-yellow-dark: #424242;
    --bg-label-yellow: #f2f2f2;
    --bg-green: #2c2c2c;
    --bg-green-2: #2c2c2c;
    --bg-green-3: #2c2c2c;
    --bg-green-4: #2c2c2c;
    --bg-green-light: #424242;
    --bg-green-light-2: #2c2c2c;
    --bg-green-light-3: #424242;
    --bg-green-light-4: #424242;
    --bg-green-light-5: #424242;
    --bg-label-green: #f2f2f2;
    --bg-pink: #2c2c2c;
    --bg-pink-light: #2c2c2c;
    --bg-pink-light-2: #424242;
    --bg-pink-light-3: #424242;
    --bg-pink-dark: #fff;
    --bg-turquoise: #2c2c2c;
    --bg-turquoise-2: #2c2c2c;
    --bg-turquoise-light: #424242;
    --bg-violet: #2c2c2c;
    --bg-violet-light: #2c2c2c;
    --bg-violet-light-2: #424242;
    --bg-brown: #2c2c2c;
    --bg-brown-2: #2c2c2c;
    --bg-brown-3: #2c2c2c;
    --bg-brown-dark: #2c2c2c;
    --bg-brown-light: #2c2c2c;
    --bg-brown-light-2: #424242;
    --bg-brown-light-3: #424242;
    *::after,
    *::before {
      filter: brightness(0) invert(1);
    }
    .slick-arrow {
      filter: brightness(0) invert(1) !important;
    }
    img {
      filter: grayscale(100%);
    }
    .b-image-filter {
      filter: brightness(0) invert(1);
    }
    @media only screen and (min-width: $bp-tablet + 1) {
      .service-hero_right-image {
        filter: grayscale(100%);
      }
    }
    .aiwa-element-widget,
    .acbsww_container {
      filter: grayscale(100%);
    }
  }
  &[data-color-scheme="blue"] {
    background: #B0CFF7;
    --white: #B0CFF7;
    --white-rgba-2: 176, 207, 247;
    --white-2: #B0CFF7;
    // --black: #000;
    // --black-rgba: 0, 0, 0;
    // --black-rgba-2: 0, 0, 0;

    --aiwa-color-primary: #1C335C;
    --blue: #1C335C;
    --blue-rgba: 28, 51, 92;
    --blue-light: #8BB0DE;
    --blue-light-2: #1C335C;
    --gray: #151413;
    --gray-2: #8BB0DE;
    --gray-rgba: 28, 51, 92;
    --gray-bg: #8BB0DE;
    --gray-bg-rgba: 139, 176, 222;
    --gray-bg-2: #1C335C;
    --gray-second: #1C335C;
    --gray-second-rgba: 28, 51, 92;
    --gray-light: #1C335C;
    --gray-light-rgba: 28, 51, 92;
    --gray-light-2: #8BB0DE;
    --gray-light-3: #8BB0DE;
    --gray-dark: #1C335C;
    --gray-dark-rgba: 28, 51, 92;
    --gray-dark-2: #1C335C;
    --gray-dark-2-rgba: 28, 51, 92;
    --gray-dark-3: #1C335C;
    --green: #1C335C;
    --green-light: #8BB0DE;
    --green-light-2: #1C335C;
    --green-rgba: 28, 51, 92;
    --red: #1C335C;
    --red-2: #1C335C;
    --pink: #1C335C;
    --pink-light: #8BB0DE;

    --bg-blue-sky: #1C335C;
    --bg-blue: #1C335C;
    --bg-blue-2: #1C335C;
    --bg-blue-3: #1C335C;
    --bg-blue-4: #1C335C;
    --bg-blue-5: #1C335C;
    --bg-blue-6: #1C335C;
    --bg-blue-light: #1C335C;
    --bg-blue-light-2: #8BB0DE;
    --bg-blue-light-3: #8BB0DE;
    --bg-blue-light-4: #8BB0DE;
    --bg-blue-light-5: #8BB0DE;
    --bg-label-blue: #8BB0DE;
    --bg-label-blue-2: #8BB0DE;
    --bg-yellow: #1C335C;
    --bg-yellow-2: #1C335C;
    --bg-yellow-light: #8BB0DE;
    --bg-yellow-light-2: #8BB0DE;
    --bg-yellow-light-3: #8BB0DE;
    --bg-yellow-dark: #1C335C;
    --bg-label-yellow: #8BB0DE;
    --bg-green: #1C335C;
    --bg-green-2: #1C335C;
    --bg-green-3: #1C335C;
    --bg-green-4: #1C335C;
    --bg-green-light: #8BB0DE;
    --bg-green-light-2: #1C335C;
    --bg-green-light-3: #8BB0DE;
    --bg-green-light-4: #8BB0DE;
    --bg-green-light-5: #8BB0DE;
    --bg-label-green: #8BB0DE;
    --bg-pink: #1C335C;
    --bg-pink-light: #1C335C;
    --bg-pink-light-2: #8BB0DE;
    --bg-pink-light-3: #8BB0DE;
    --bg-pink-dark: #1C335C;
    --bg-turquoise: #1C335C;
    --bg-turquoise-2: #1C335C;
    --bg-turquoise-light: #8BB0DE;
    --bg-violet: #1C335C;
    --bg-violet-light: #1C335C;
    --bg-violet-light-2: #8BB0DE;
    --bg-brown: #1C335C;
    --bg-brown-2: #1C335C;
    --bg-brown-3: #1C335C;
    --bg-brown-dark: #1C335C;
    --bg-brown-light: #1C335C;
    --bg-brown-light-2: #8BB0DE;
    --bg-brown-light-3: #8BB0DE;
    img,
    .acbsww_container {
      filter: grayscale(100%);
    }
    .b-image-filter:not(.dropdown-menu_link) {
      filter: invert(18%) sepia(9%) saturate(4571%) hue-rotate(181deg) brightness(93%) contrast(95%);
    }
    .dropdown-menu_link {
      filter: none;
      img {
        filter: brightness(0%);
      }
    }
    .icon-minus::after {
      filter: brightness(0%);
    }
    @media only screen and (min-width: $bp-tablet + 1) {
      .service-hero_right-image {
        filter: grayscale(100%);
      }
    }
  }
  // image
  &[data-filter-image="blackWhite"],
  &[data-color-scheme="whiteBlack"] {
    img,
    svg {
      filter: grayscale(100%);
    }
    .b-image-filter {
      filter: brightness(0%);
    }
    @media only screen and (min-width: $bp-tablet + 1) {
      .service-hero_right-image {
        filter: grayscale(100%);
      }
    }
  }
  &[data-filter-image="hide"] {
    img,
    .service-hero_right-image {
      opacity: 0;
    }
  }
}
