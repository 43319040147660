input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  box-shadow: 0 0 0px 1000px #ffffff inset !important;
  background-color: #ffffff !important;
  background-clip: border-box !important;
}

textarea {
  height: 40px;
  @include desktopStyle {
    height: get-vw(40px);
  }
}

.input {
	width: 100%;
	display: block;
	outline: 0 none;
	position: relative;
	transition: all 0.3s;
	text-align: left;
	font-family: $font-main-SB;
	font-size: 1.8rem;
	padding: 32px 24px 16px;
	line-height: 1.3;
	border-radius: 12px;
	background-color: var(--white);
	text-transform: none;
	border: 1px solid var(--gray-second);
  color: var(--gray-dark);
  @include desktopStyle {
    border-radius: get-vw(12px);
    padding: get-vw(32px) get-vw(24px) get-vw(16px);
  }
  @media only screen and (max-width: $bp-tablet) {
    font-size: 1.6rem;
    padding: 26px 16px 12px;
    line-height: 1.6;
  }

	&::placeholder {
    opacity: 0;
	}

  &:hover,
  &:focus {
    border-color: var(--gray-light);
  }

  &:not(:placeholder-shown),
  &:focus {
    & + .form_placeholder {
      font-size: 1.4rem;
      top: 12px;
      @include desktopStyle {
        top: get-vw(12px);
      }
      @media only screen and (max-width: $bp-tablet) {
        font-size: 1.2rem;
      }
    }
  }

  &.b-error {
    border-color: var(--red);
    & + .form_placeholder {
      color: var(--red);
    }
  }

	&[disabled],
	&[disabled="disabled"] {
		cursor: not-allowed;
		opacity: 0.7;
	}

  &--right-icon {
    padding-right: 60px;
    @include desktopStyle {
      padding-right: get-vw(60px);
    }
    @media only screen and (max-width: $bp-tablet) {
      padding-right: 44px;
    }
  }
}

.select {
  font-family: $font-main-SB;
	font-size: 1.8rem;
	line-height: 1.3;
	border-radius: 12px;
	background-color: var(--white);
  color: var(--gray-dark);
  @include desktopStyle {
    border-radius: get-vw(12px);
  }

  &.b-error {
    .select__control {
      border-color: var(--red);
    }
    & + .form_placeholder {
      color: var(--red);
    }
  }
  
  &.b-has-value,
  .select__control.select__control--is-focused {
    & + .form_placeholder {
      font-size: 1.4rem;
      top: 12px;
      @include desktopStyle {
        top: get-vw(12px);
      }
      @media only screen and (max-width: $bp-tablet) {
        font-size: 1.2rem;
      }
    }
  }

  .select__control {
	  border: 1px solid var(--gray-second);
    box-shadow: none;
    font-family: $font-main-SB;
	  border-radius: 12px;
    padding-right: 28px;
    cursor: pointer;
    transition: all 0.3s;
    background: transparent;
    @include desktopStyle {
      border-radius: get-vw(12px);
      padding-right: get-vw(28px);
    }
    @media only screen and (max-width: $bp-tablet) {
      font-size: 1.6rem;
      line-height: 1.6;
    }

    &:hover,
    &.select__control--is-focused {
      border-color: var(--gray-light);
    }

    &.select__control--menu-is-open {
      .select__dropdown-indicator {
        transform: rotate(180deg);
      }
    }
  }

  .select__value-container {
	  padding: 32px 16px 16px 24px;
    margin: 0;
    position: relative;
    z-index: 2;
    @include desktopStyle {
      padding: get-vw(32px) get-vw(16px) get-vw(16px) get-vw(24px);
    }
    @media only screen and (max-width: $bp-tablet) {
      padding: 26px 16px 12px;
    }
  }

  .select__placeholder {
    display: none;
  }

  .select__single-value {
    color: var(--gray-dark);
  }

  .select__input-container {
	  padding: 0;
    margin: 0;
    color: var(--gray-dark);
  }

  .select__dropdown-indicator {
    padding: 0;
    transition: all 0.3s;
    border-top: 4px solid var(--gray-dark);
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    @include desktopStyle {
      border-top-width: get-vw(4px);
      border-left-width: get-vw(4px);
      border-right-width: get-vw(4px);
    }
    @media only screen and (max-width: $bp-phone) {
      border-top-color: #8A8989;
    }
    svg {
      display: none;
    }
  }

  .select__indicator-separator {
    display: none;
  }

  .select__menu {
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0px 4px 6px -2px rgba(var(--gray-dark-2-rgba), 0.03), 0px 12px 16px -4px rgba(var(--gray-dark-2-rgba), 0.08);
    background: var(--white);
    @include desktopStyle {
      border-radius: get-vw(12px);
    }
  }

  .select__menu {
    z-index: 3;
  }

  .select__menu-list {
    border: 1px solid var(--gray-light-3);
    max-height: 320px;
    padding: 10px;
    @include desktopStyle {
      padding: get-vw(10px);
    }
    &::-webkit-scrollbar {
      width: 8px;
      @include desktopStyle {
        width: get-vw(8px);
      }
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #EAECF0;
      border-radius: 8px;
    }
  }

  .select__option {
	  padding: 10px 42px 10px 14px;
    cursor: pointer;
    transition: all 0.3s;
    font-size: 1.6rem;
    color: var(--gray-dark-2);
    line-height: 1.6;
    border-radius: 6px;
    position: relative;
    @include desktopStyle {
      border-radius: get-vw(6px);
      padding: get-vw(10px) get-vw(42px) get-vw(10px) get-vw(14px);
    }

    &.select__option--is-focused,
    &.select__option--is-selected {
      background: var(--white-2);
    }
    &.select__option--is-selected {
      &::after {
        opacity: 1;
      }
    }
    &::after {
      content: '';
      position: absolute;
      display: block;
      opacity: 0;
      transition: opacity 0.3s;
      background: url('~~/app/assets/images/icons/check.svg') center center no-repeat;
      background-size: contain;
      top: 50%;
      width: 20px;
      height: 20px;
      right: 14px;
      margin-top: -10px;
      @include desktopStyle {
        width: get-vw(20px);
        height: get-vw(20px);
        right: get-vw(14px);
        margin-top: get-vw(-10px);
      }
    }
  }

  &.select-with-dot {
    .select__option,
    .select__single-value {
      display: flex;
      align-items: center;
      &:before {
        content: ' ';
        display: block;
        border-radius: 100%;
        flex-shrink: 0;
        width: 12px;
        height: 12px;
        margin-right: 12px;
        @include desktopStyle {
          width: get-vw(12px);
          height: get-vw(12px);
          margin-right: get-vw(12px);
        }
      }
    }
  }
}

.textarea {
	position: relative;
	transition: all 0.3s;
	padding: 32px 24px 16px;
	border-radius: 12px;
	background-color: var(--white);
	border: 1px solid var(--gray-second);
  @include desktopStyle {
    border-radius: get-vw(12px);
    padding: get-vw(32px) get-vw(24px) get-vw(16px);
  }
  @media only screen and (max-width: $bp-tablet) {
    padding: 26px 16px 12px;
  }

  &:hover,
  &:focus {
    border-color: var(--gray-light);
  }

  &.b-error {
    border-color: var(--red);
    .form_placeholder {
      color: var(--red);
    }
  }

  &_input {
    width: 100%;
    display: block;
    outline: 0 none;
    position: relative;
    transition: all 0.3s;
    text-align: left;
    font-family: $font-main-SB;
    font-size: 1.8rem;
    padding: 0;
    line-height: 1.3;
    background: transparent;
    text-transform: none;
    border: none;
    color: var(--gray-dark);
    max-width: 100%;
    @media only screen and (max-width: $bp-tablet) {
      font-size: 1.6rem;
      line-height: 1.6;
    }
  
    &::placeholder {
      opacity: 0;
    }
  
    &:not(:placeholder-shown),
    &:focus {
      & + .form_placeholder {
        font-size: 1.4rem;
        top: 12px;
        @include desktopStyle {
          top: get-vw(12px);
        }
        @media only screen and (max-width: $bp-tablet) {
          font-size: 1.2rem;
        }
      }
    }

    &::-webkit-scrollbar {
      width: 4px;
      @include desktopStyle {
        width: get-vw(4px);
      }
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: var(--blue);
      border-radius: 10px;
      transition: visibility 0.3s;
      @include desktopStyle {
        border-radius: get-vw(10px);
      }
    }
  }
}

.form {
  &_row {
    display: flex;
    gap: 24px;
    grid-gap: 24px;
    @include desktopStyle {
      gap: get-vw(24px);
      grid-gap: get-vw(24px);
    }
    @media only screen and (max-width: $bp-tablet) {
      display: block;
    }

    & > .form_item {
      flex: 1;
    }
  }

  &_item {
    position: relative;
    margin-bottom: 24px;
    @include desktopStyle {
      margin-bottom: get-vw(24px);
    }
    @media only screen and (max-width: $bp-phone) {
      margin-bottom: 12px;
    }
  }

  &_select {
    @media only screen and (max-width: $bp-tablet) {
      .form_placeholder {
        right: 45px;
      }
    }
  }

  &_input-right-icon {
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    color: var(--gray-dark);
    cursor: pointer;
    z-index: 2;
    @include desktopStyle {
      right: get-vw(24px);
      width: get-vw(24px);
      height: get-vw(24px);
    }
    @media only screen and (max-width: $bp-tablet) {
      right: 12px;
      width: 20px;
      height: 20px;
    }
    &.b-small {
      width: 16px;
      height: 16px;
      @include desktopStyle {
        width: get-vw(16px);
        height: get-vw(16px);
      }
    }
  }

  &_placeholder {
    position: absolute;
    color: var(--gray-light);
    position: absolute;
    top: 24px;
    left: 24px;
    right: 24px;
    font-family: $font-main-SB;
    font-size: 1.8rem;
    line-height: 1.3;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    z-index: 1;
    cursor: pointer;
    user-select: none;
    transition: all 0.3s;
    @include desktopStyle {
      top: get-vw(24px);
      left: get-vw(24px);
      right: get-vw(24px);
    }
    @media only screen and (max-width: $bp-tablet) {
      font-size: 1.6rem;
      top: 22px;
      left: 16px;
      right: 16px;
    }
  }

  &_bottom {
    margin-top: 56px;
    @include desktopStyle {
      margin-top: get-vw(56px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-top: 44px;
    }
    @media only screen and (max-width: $bp-phone) {
      margin-top: 32px;
    }

    &--sm {
      margin-top: 32px;
      @include desktopStyle {
        margin-top: get-vw(32px);
      }
    }
  }

  &_labels {
    font-size: 1.4rem;
    line-height: 22px;
    margin-top: 12px;
    gap: 20px;
    grid-gap: 20px;
  }

  &_labels-item {
    cursor: pointer;
    transition: color 0.3s;
    &:hover {
      color: var(--blue);
    }
  }

  &_error-text {
    margin-top: 12px;
    font-size: 1.4rem;
    color: var(--red);
  }

  &_checkbox  {
    display: inline-block;
    vertical-align: middle;
    font-family: $font-main-SB;
    color: var(--gray);
    font-size: 1.6rem;
    line-height: 1.3;
  }
  &_checkbox-control {
    display: none;
    &:checked + .form_checkbox-text {
      &::before {
        border-color: var(--blue);
      }
      &::after {
        transform: scale(1);
        opacity: 1;
      }
    }
    &.b-error + .form_checkbox-text {
      color: var(--red);
      &::before {
        border-color: var(--red);
      }
    }
  }
  &_checkbox-text {
    position: relative;
    display: block;
    padding-left: 32px;
    cursor: pointer;
    @include desktopStyle {
      padding-left: get-vw(32px);
    }
    &:hover {
      &::before {
        border-color: var(--blue);
        background: #F3F7FF;
      }
    }
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      transition: all 0.3s;
    }
    &::before {
      width: 20px;
      height: 20px;
      top: 0;
      left: 0;
      background: var(--white);
      border-radius: 6px;
      filter: none !important;
      border: 1px solid var(--gray-second);
      @include desktopStyle {
        width: get-vw(20px);
        height: get-vw(20px);
        border-radius: get-vw(6px);
      }
    }
    &::after {
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 3L4.5 8.5L2 6' stroke='%23499BD5' stroke-width='1.6666' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      opacity: 0;
      transform: scale(0.75);
      @include desktopStyle {
        top: get-vw(4px);
        left: get-vw(4px);
        width: get-vw(12px);
        height: get-vw(12px);
      }
    }
    a {
      color: var(--blue);
    }
  }
}

.btn {
  text-align: center;
  padding: 14px 38px;
  background: var(--blue);
  border: 2px solid var(--blue);
  border-radius: 30px;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  color: var(--white);
  font-family: $font-main-SB;
  font-size: 1.8rem;
  line-height: 1.3;
  transition: all 0.3s;
  position: relative;
  display: inline-flex;
  justify-content: center;
  vertical-align: middle;
  @include desktopStyle {
    border-radius: get-vw(30px);
    padding: get-vw(14px) get-vw(38px);
    border-width: get-vw(2px);
  }
  &:hover {
    background: var(--blue-light-2);
    border-color: var(--blue-light-2);
  }
  @media only screen and (max-width: $bp-tablet) {
    font-size: 1.6rem;
  }

  &.bg-bordered {
    background: transparent;
    color: var(--blue);
    &:hover {
      color: var(--white);
      background: var(--blue-light-2);
      border-color: var(--blue-light-2);
    }
  }

  &.bg-white {
    background: var(--white);
    border-color: var(--white);
    color: var(--gray-dark);
    &:hover {
      color: var(--white);
      background: var(--blue-light-2);
      border-color: var(--blue-light-2);
    }
  }

  &.bg-pink {
    background: var(--bg-pink-dark);
    border-color: var(--bg-pink-dark);
    &:hover {
      background: var(--bg-pink-dark);
      border-color: var(--bg-pink-dark);
    }
  }

  &.b-only-icon {
    width: 56px;
    height: 56px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @include desktopStyle {
      width: get-vw(56px);
      height: get-vw(56px);
    }
    @media only screen and (max-width: $bp-tablet) {
      width: 40px;
      height: 40px;
    }
    img {
      display: block;
    }
  }

  &.b-loading {
    color: transparent;
  }

  &.b-with-icon {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    grid-gap: 8px;
    @include desktopStyle {
      gap: get-vw(8px);
      grid-gap: get-vw(8px);
    }
  }

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    margin-top: -10px;
    margin-left: -10px;
    height: 20px;
    border-width: 2px;
  }

  &[disabled],
	&[disabled="disabled"] {
		cursor: not-allowed;
    border-color: #CED8E0;
    background: #CED8E0;
    color: var(--white);
    &:hover {
      border-color: #CED8E0;
      background: #CED8E0;
      color: var(--white);
    }
	}
}

.btn-app {
  text-align: center;
  padding: 12px 8px;
  background: #151413;
  border-radius: 12px;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  color: #fff;
  font-family: $font-main-SB;
  display: flex;
  justify-content: center;
  align-items: center;
  @include desktopStyle {
    padding: get-vw(12px);
    border-radius: get-vw(12px);
  }

  img {
    display: block;
    width: auto;
    height: 40px;
    margin: 0 auto;
    @include desktopStyle {
      height: get-vw(40px);
    }
    @media only screen and (max-width: $bp-tablet) {
      height: 32px;
    }
  }

  &--md {
    @media only screen and (max-width: $bp-tablet) {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    img {
      height: 32px;
      @include desktopStyle {
        height: get-vw(32px);
      }
      @media only screen and (max-width: $bp-tablet) {
        height: 28px;
      }
    }
  }
  &--sm {
    img {
      height: 28px;
      @include desktopStyle {
        height: get-vw(28px);
      }
    }
  }
}
